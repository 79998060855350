import _ from "lodash";
import { useOktaAuth } from "@okta/okta-react";
import { Client } from "@twilio/conversations";
import { useContext, useEffect, useRef, useState } from "react";
import { Sidebar } from "../../components/sidebar/Sidebar";
import { AlertContext, BaseContext } from "../../lib/context/context";
import { Spinner } from "../../components/spinner/Spinner";
import { Messages, SelectedChat } from "../../pages/messages/Messages";
import { ChatView } from "../messaging/chatView/ChatView";
import { AvailabilityManagement } from "../../pages/availabilityManagement/AvailabilityManagement";
import { PatientMessages } from "../../pages/patientMessages/PatientMessages";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useChats } from "../../lib/hooks/useChats";
import { Patients } from "../../pages/patient/Patient";
import { Notifications } from "../../pages/notifications/Notifications";
import { Schedule } from "../../pages/schedule/Schedule";
import styles from "./style.module.css";
import { useAlerts } from "../../lib/hooks/useAlerts";
import { AlertBanner } from "../../components/alertBanner/AlertBanner";
import { ProviderInfo } from "../../lib/interfaces/user";
import { useProvider } from "../../lib/hooks/useProvider";

import chatAPI from "../../lib/apis/chat";
import { useVisits } from "../../lib/hooks/useVisits";
import { ProviderType } from "../../lib/apis/types/provider.types";
import PatientSearch from "../../pages/patient/PatientSearch";
import { clearVideoStatus } from "../../lib/apis/availabilityManagement";
import { PatientDetails } from "../../pages/patient/PatientResults";
import { Visit } from "../../lib/interfaces/visits";
import { BaseSidebarContent } from "../../components/sidebar/BaseSidebarContent";
import {
  PillarDetailProps,
  SelectedPillarDataProps,
  SelectedPillarTemplateProps,
} from "../../lib/interfaces/carePlan";
import { Option } from "../../lib/interfaces/input";
import { UserClaims } from "@okta/okta-auth-js";
import { isAuthenticationFlagEnabled } from "../../lib/util/flags";
import { useAuthContext } from "../../contexts/AuthContext";

const USER_TYPE = "provider";

export const Base = () => {
  const isAuthEnabled = isAuthenticationFlagEnabled();
  const oktaAuthContext = !isAuthEnabled ? useOktaAuth() : null;
  const oktaAuth = oktaAuthContext ? oktaAuthContext.oktaAuth : null;
  const authState = oktaAuthContext ? oktaAuthContext.authState : null;
  const [oktaUser, setOktaUser] = useState<UserClaims | null>(null);

  const { token, user, loading } = useAuthContext();

  const { getAllChats } = useChats();
  const { getProvider, getProviderByEmail, getAllProviders } = useProvider();
  const { alerts, pushAlert, closeAlert, clearAlerts } = useAlerts();

  const [popoutChat, setPopoutChat] = useState<SelectedChat[]>([]);
  const [userInfo, setUserInfo] = useState<ProviderInfo>();
  const [visitsWithProvider, setVisisWithProvider] = useState<Visit[]>([]);
  const [unreadChats, setUnreadChats] = useState<string[]>([]);
  const [allProviders, setAllProviders] = useState<ProviderInfo[]>();
  const [twilio, setTwilio] = useState<Client>();
  const [isPillarPageShow, setIsPillarPageShow] = useState<Boolean>(false);
  const [isPillarEditShow, setIsPillarEditShow] = useState<Boolean>(false);

  const { getAllVisits } = useVisits();
  const [pillarData, setPillarData] = useState<PillarDetailProps | null>(null);

  const { path } = useRouteMatch();
  const popoutChatRef = useRef<SelectedChat[]>([]);
  const selectedChatRef = useRef<SelectedChat>();
  const unreadChatsRef = useRef<string[]>([]);
  const { pathname } = useLocation();
  const [searchQuery, setSearchQuery] = useState<PatientDetails | undefined>();
  const [selectedPillarData, setSelectedPillarData] =
    useState<SelectedPillarDataProps | null>(null);
  const [showAddNewCarePlanModal, setShowAddNewCarePlanModal] =
    useState<boolean>(false);
  const [groupTypeOptions, setGroupTypeOptions] = useState<Option[]>([]);
  const [selectedTemplateData, setSelectedTemplateData] =
    useState<SelectedPillarTemplateProps | null>(null);
  const [refetchPillarTemplate, setRefetchPillarTemplate] =
    useState<boolean>(false);

  useEffect(() => {
    if (isAuthEnabled) {
      if (user && token) {
        const email = user.email;
        getProviderByEmail(email).then((_userInfo) => {
          setUserInfo(_userInfo);
        });
        getAllProviders().then(setAllProviders);
      }
    } else {
      if (authState?.isAuthenticated && !oktaUser) {
        oktaAuth &&
          oktaAuth.getUser().then((_user) => {
            setOktaUser(_user);
          });
      }
    }
  }, [user, token, authState, oktaUser, isAuthEnabled]);

  useEffect(() => {
    if (!isAuthEnabled && oktaUser) {
      const providerId = oktaUser.sub;
      getProvider(providerId).then((_userInfo) => {
        setUserInfo(_userInfo);
      });
      getAllProviders().then(setAllProviders);
    }
  }, [oktaUser, isAuthEnabled]);

  useEffect(() => {
    if (!twilio && (user || oktaUser)) {
      initializeTwilio();
    }
  }, [user, oktaUser, twilio]);

  const getAllVisitsWithProvider = async (providerId: string) => {
    const visitsWithProvider = await getAllVisits("", "", providerId);
    setVisisWithProvider(visitsWithProvider);
  };

  useEffect(() => {
    if (userInfo && userInfo?.providerType === ProviderType.OutsideSpecialist) {
      getAllVisitsWithProvider(userInfo.id as string);
    }
  }, [userInfo]);

  useEffect(() => {
    clearAlerts();
  }, [pathname]);

  useEffect(() => {
    if (user && allProviders) {
      const userId = user?.["custom:okta_sub"] || user?.sub || "";
      getAllChats(userId, USER_TYPE).then((chats) => {
        if (chats) {
          const unreadChats = chats
            .filter((chat) => chat.unread)
            .map((chat) => chat.id);
          setUnreadChats(unreadChats);
          unreadChatsRef.current = unreadChats;
        }
      });
    }
  }, [user, allProviders]);

  useEffect(() => {
    twilio?.on("conversationUpdated", ({ conversation, updateReasons }) => {
      // conversation.sid !== selectedChatRef.current?.id means if we just received a new message from a different chat
      // unreadChatsRef.current.includes(selectedChatRef.current?.id) means if we are reading an unread message from a different chat
      if (
        _.union(updateReasons, ["lastReadMessageIndex", "lastMessage"]) &&
        (conversation.sid !== selectedChatRef.current?.id ||
          unreadChatsRef.current.includes(selectedChatRef.current?.id))
      ) {
        if (
          conversation.lastReadMessageIndex !==
            conversation.lastMessage?.index &&
          !unreadChats.includes(conversation.sid)
        ) {
          setUnreadChats((currentUnreadChats) => [
            ...currentUnreadChats,
            conversation.sid,
          ]);
          unreadChatsRef.current = [
            ...unreadChatsRef.current,
            conversation.sid,
          ];
        } else if (
          conversation.lastReadMessageIndex === conversation.lastMessage?.index
        ) {
          setUnreadChats((currentUnreadChats) =>
            currentUnreadChats.filter((chatId) => chatId !== conversation.sid)
          );
          unreadChatsRef.current = unreadChatsRef.current.filter(
            (chatId) => chatId !== conversation.sid
          );
        }
      }
    });
  }, [twilio]);

  const initializeTwilio = async () => {
    try {
      const res = await chatAPI.fetchToken("chat", "");
      const token = res.token;
      if (token) {
        const client = new Client(token);
        setTwilio(client);
      }
    } catch (error) {
      console.error("Error initializing Twilio client:", error);
    }
  };

  const onChatSelect = (chat: SelectedChat) => {
    selectedChatRef.current = chat;
  };

  const openPopoutChat = (chat: SelectedChat, template?: string) => {
    if (!popoutChatRef.current.map((chat) => chat.id).includes(chat.id)) {
      popoutChatRef.current = [
        ...popoutChatRef.current,
        {
          ...chat,
          template: template ? template : "",
        },
      ];
      setPopoutChat(popoutChatRef.current);
    }
  };

  const closePopoutChat = (chat: SelectedChat) => {
    if (popoutChat.map((chat) => chat.id).includes(chat.id)) {
      popoutChatRef.current = popoutChatRef.current.filter(
        (_chat) => _chat.id !== chat.id
      );
    }
    setPopoutChat(popoutChatRef.current);
  };

  const modifiedUser = {
    ...user,
    sub: user?.["custom:okta_sub"] || user?.sub || "",
    email: user?.email || "",
  };

  const contextValue: BaseContext = {
    user: modifiedUser,
    userInfo,
    allProviders,
    visitsWithProvider,
    isPillarPageShow,
    openPopoutChat,
    closePopoutChat,
    popoutChat,
    twilio,
    searchQuery,
    setSearchQuery,
    setIsPillarPageShow,
    pillarData,
    setPillarData,
    isPillarEditShow,
    setIsPillarEditShow,
    setSelectedPillarData,
    selectedPillarData,
    setShowAddNewCarePlanModal,
    showAddNewCarePlanModal,
    setGroupTypeOptions,
    groupTypeOptions,
    setSelectedTemplateData,
    selectedTemplateData,
    setRefetchPillarTemplate,
    refetchPillarTemplate,
  };

  const alertContextValue = {
    pushAlert,
    clearAlerts,
  };

  return !loading ? (
    <BaseContext.Provider value={contextValue}>
      <AlertContext.Provider value={alertContextValue}>
        <div id="base" className={styles.base}>
          <div className={styles.sideBarContainer}>
            <Sidebar>
              <BaseSidebarContent
                user={userInfo}
                messageBadge={unreadChats.length > 0}
              />
            </Sidebar>
          </div>
          <div className={styles.pageContainer}>
            {alerts.length > 0 && (
              <div className={styles.alertContainer}>
                {alerts.map((alert) => (
                  <AlertBanner
                    type={alert.type}
                    message={alert.message}
                    key={alert.id}
                    onClose={
                      typeof alert.closeable === "undefined" || alert.closeable
                        ? () => closeAlert(alert.id)
                        : undefined
                    }
                    withIcon={alert.withIcon}
                  />
                ))}
              </div>
            )}
            <Switch>
              <Route
                path={`${path}messages`}
                render={() => {
                  clearVideoStatus();
                  return <Messages onChatSelect={onChatSelect} />;
                }}
              />
              <Route
                path={`${path}patients/messages`}
                component={PatientMessages}
              />
              <Route
                path={`${path}availability`}
                component={AvailabilityManagement}
              />
              <Route
                path={`${path}patient`}
                render={() => {
                  clearVideoStatus();
                  return <Patients />;
                }}
              />
              <Route
                path="/patientsearch"
                render={() => {
                  clearVideoStatus();
                  return (
                    <PatientSearch
                      headingText="Member Search"
                      controlAligment="center"
                      isPatientSearchPage={true}
                    />
                  );
                }}
              />
              <Route
                path={`${path}notifications`}
                render={() => {
                  clearVideoStatus();
                  return <Notifications />;
                }}
              />
              <Route
                path={`${path}scheduling`}
                render={() => {
                  clearVideoStatus();
                  return <Schedule />;
                }}
              />

              <Redirect path={path} to={`${path}scheduling`} />
            </Switch>
          </div>
          {popoutChat.length > 0 && (
            <div className={styles.popoutChats}>
              {popoutChat.map((chat) => {
                return (
                  <div key={chat.id} className={styles.popoutChatContainer}>
                    <ChatView
                      chat={chat}
                      key={chat.id}
                      popout
                      message={chat.template}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </AlertContext.Provider>
    </BaseContext.Provider>
  ) : (
    <Spinner size={96} />
  );
};

export const useBaseContext = () => {
  return useContext(BaseContext);
};
