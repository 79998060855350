import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Amplify } from "aws-amplify";
import {
  fetchAuthSession,
  JWT,
  signInWithRedirect,
  signOut as _signOut,
} from "aws-amplify/auth";
import { sessionStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import axios from "axios";
import cognitoConfig from "../config/cognito.config";
import { Modal } from "../components/modal/Modal";
import { LogoutPromptForm } from "../containers/logout/LogoutPromptForm";

const API_URL = process.env.REACT_APP_API_URL;
const LOGOUT_PROMPT_REMAINING_TIME = 2 * 60; // 2 minutes
const LOGOUT_PROMPT_TIMEOUT = 15 * 60; // 15 minutes

Amplify.configure({
  Auth: cognitoConfig,
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

axios.defaults.baseURL = API_URL;

export interface AuthContextType {
  loading: boolean;
  token?: JWT | null;
  user?: CognitoUser | null;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
}

export interface BaseUser {
  sub: string;
  email: string;
  [key: string]: any;
}

export interface CognitoUser extends BaseUser {
  "custom:okta_sub"?: string;
}

export const AuthContext = createContext<AuthContextType>({
  loading: false,
  signIn: async () => {},
  signOut: async () => {},
});

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const history = useHistory();
  const [token, setToken] = useState<JWT | null>(null);
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const logoutTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const logoutPromptTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const checkAuth = async () => {
      setLoading(true);
      try {
        const session = await fetchAuthSession();
        if (!session.tokens) {
          history.push("/login");
        } else {
          setToken(session.tokens?.idToken || null);
          setUser((session.tokens?.idToken?.payload as CognitoUser) || null);
        }
      } catch (error) {
        console.error("Error fetching Cognito session:", error);
        await signIn();
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

  const signIn = async () => {
    await signInWithRedirect({ provider: { custom: "EntraOIDC" } });
  };

  const signOut = async () => {
    await _signOut({ global: true });

    setToken(null);
    setUser(null);
  };

  const refreshToken = async () => {
    try {
      const session = await fetchAuthSession({ forceRefresh: true });
      const newToken = session.tokens?.idToken;
      if (newToken) {
        setToken(newToken);
        return newToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      await signIn();
    }
    return null;
  };

  const handleLogoutNow = () => {
    setShowLogoutModal(false);
    signOut();
  };

  const handleStayConnected = async () => {
    setShowLogoutModal(false);
    await refreshToken();
  };

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token.toString()}`;
    }
  }, [token]);

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const newToken = await refreshToken();
          if (newToken) {
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${newToken.toString()}`;
            return axios(originalRequest);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  useEffect(() => {
    if (token) {
      const currentTime = Math.floor(Date.now() / 1000);
      const tokenExpiry = token.payload.exp;

      if (!tokenExpiry) return;

      const isInVideoChat = checkIfInVideoChat();
      if (isInVideoChat) {
        refreshToken();
      } else {
        if (tokenExpiry - currentTime < LOGOUT_PROMPT_REMAINING_TIME) {
          setShowLogoutModal(true);
        } else if (logoutTimeoutRef.current) {
          clearTimeout(logoutTimeoutRef.current);
        }

        logoutTimeoutRef.current = setTimeout(() => {
          setShowLogoutModal(true);
        }, (tokenExpiry - currentTime - LOGOUT_PROMPT_REMAINING_TIME) * 1000);

        if (logoutPromptTimeoutRef.current) {
          clearTimeout(logoutPromptTimeoutRef.current);
        }

        logoutPromptTimeoutRef.current = setTimeout(() => {
          if (showLogoutModal) {
            handleLogoutNow();
          }
        }, LOGOUT_PROMPT_TIMEOUT);
      }
    }
  }, [token]);

  const checkIfInVideoChat = () => {
    const video = localStorage.getItem("video");
    const videodata = video ? JSON.parse(video) : null;

    return videodata && videodata.videoJoined;
  };

  return (
    <AuthContext.Provider value={{ loading, token, user, signIn, signOut }}>
      {children}
      <Modal
        dismissable={false}
        visible={showLogoutModal}
        onCloseModal={() => {
          setShowLogoutModal(false);
        }}
        title="Would you like to remain connected?"
      >
        <LogoutPromptForm
          onLogoutNow={handleLogoutNow}
          onStayConnected={handleStayConnected}
        />
      </Modal>
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
