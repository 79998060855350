import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Spinner } from "../../components/spinner/Spinner";
import { Button } from "../../components/button/Button";

export default function LoginRedirect() {
  const { token, user, loading, signIn } = useContext(AuthContext);

  return loading ? (
    <Spinner />
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Button onClick={signIn} label="Sign In with Microsoft Entra" />
    </div>
  );
}
