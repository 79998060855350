import { SetStateAction } from "react";
import { Resource, ResourcePreview } from "./resource";
import { PatientInfo } from "./user";

export interface Action {
  actionId: string;
  category: string;
  activities: string[];
  quantity: number;
  units: string;
  frequency: number;
  per: string;
  modifiedOn?: string;
  modifiedBy?: string;
}

export interface ActionPlan {
  resourceIds?: string[];
  comment?: {
    comment: string;
    modifiedOn?: string;
    modifiedBy?: string;
  };
  actions?: Action[];
}

export interface Focus {
  value?: string;
  comments?: string;
  modifiedOn?: string;
  modifiedBy?: string;
}
export type Intention = Omit<Focus, "comments">;
export type LoveLetter = Omit<Focus, "comments">;

export interface CarePlan {
  firstPublishedOn?: string;
  id?: string;
  userId?: string;
  focus?: Focus;
  intention?: Intention;
  loveLetter?: LoveLetter;
  modifiedOn?: string;
  modifiedBy?: string;
  publishedOn?: string;
  publishedBy?: string;
  sentToEpicOn?: string;
  pillars?: {
    move?: ActionPlan;
    nourish?: ActionPlan;
    calm?: ActionPlan;
  };
  publish?: boolean;
}

export interface CarePlanNote {
  id: string;
  userId: string;
  title: string;
  description: string;
  modifiedOn: string;
  modifiedBy: string;
  history: {
    description: string;
    modifiedOn: string;
    modifiedBy: string;
  }[];
  attachments: Attachment[];
}

export interface Attachment {
  name: string;
  url: string;
}

export interface ContentData {
  archiveId?: string;
  careplanId?: string;
  userId: string;
  pillarId: string;
  patientGroupTypeId: string;
  patientGroupTypeName: string;
  contentName?: string;
  content: Content;
  status?: string;
  updatedAt?: string;
  updatedBy?: string;
  createdBy: string;
  createdAt?: string;
  publishedAt?: string;
  publishedBy?: string;
}

export interface PillarDetailProps {
  id: string;
  icons?: IconData;
  pillarName: string;
  contentData: ContentData;
}

type TabName = {
  id: string;
  title: string;
};
export interface CarePlanContent {
  id: string;
  title: string;
  tabName: TabName;
  body: string;
  mediaUrl?: string; // Optional media URL
  attachment?: CarePlanAttachment;
  attachments?: {
    attachmentUrl: string;
    attachmentName: string;
    title: string;
    description: string;
  }[];
}

type CarePlanAttachment = {
  attachmentUrl: string;
  attachmentName: string;
  attachmentSignedUrl?: string;
  title: string;
  description: string;
};

type Pillar = {
  id: string;
  pillarName: string;
};

export type PatientGroupType = {
  id: string;
  name: string;
};

type Content = {
  id: string;
  pillar: Pillar;
  carePlanContent: CarePlanContent[];
  introBlockTitle: string;
  patientGroupType: PatientGroupType;
};

export interface IconData {
  grayIcon?: string | undefined;
  colorIcon?: string | undefined;
  smallColorIcon?: string | undefined;
}

export interface CarePlanPillarProps {
  pillarDetail: PillarDetailProps;
  isPillarInfoAvailable: boolean;
  onClick: () => void;
  setIsPillarPageShow?: React.Dispatch<SetStateAction<Boolean>> | undefined;
}
export interface CarePlanProps {
  patient: PatientDetailProps;
  pillarDatas: PillarDetailProps | null;
  onClose: () => void;
}

export interface PatientDetailProps {
  id: string;
  name: string;
  assignedSex: string;
  dateOfBirth: string;
  mobile: string;
  identifiers?: {
    wprinternal?: string;
    epic?: string;
  };
  mrnId?: string;
}

export type StatusType = "Draft" | "Published" | "Changed" | undefined;
export interface PublishModalProps {
  patient: PatientDetailProps;
  careplanId: string;
  title: string;
  needType: string;
  updatedAt?: string;
  updatedBy?: string;
  status: StatusType;
  onEditClick?: () => void;
}

export interface ExitCareFormProps {
  onSubmit: () => void;
  exitWithOutSave: () => void;
  onCancel: () => void;
  isLoading: boolean;
  disabled: boolean;
}

export interface ConfirmPopupProps {
  onSubmit: () => void;
  onCancel: () => void;
}

export interface SelectedPillarDataProps {
  icons: IconData;
  pillarName: string;
  pillarId?: string;
}

export interface SelectedPillarTemplateProps {
  careplanId: string;
  content: Content;
  mediaUrl: string;
  patientGroupTypeId: string;
  patientGroupTypeName: string;
  pillarId: string;
  pillarName: string;
  publishedAt: string;
  status: string;
  updatedAt: string;
  updatedBy: string;
  userId: string;
  attachments?: CarePlanAttachment[];
}

export enum PublishModalTypes {
  Draft = "Draft",
  Published = "Published",
  Unpublish = "Unpublish",
  Changed = "Changed",
}

export interface CarePlanStatusModalProps {
  isVisible: boolean;
  modalType?: PublishModalTypes;
}

export interface AttachmentFileData {
  id: number;
  file: File | null;
  name: string;
}

export interface CarePlanMultipleAttachment {
  id: number;
  file: File | null;
  name: string;
  title: string;
  description: string;
}

export interface UploadedAttachment {
  attachmentUrl: string;
  attachmentName: string;
  title: string;
  description: string;
}

export interface ApiAttachment {
  attachmentUrl: string;
  attachmentName: string;
}
